'use client'

import React, { useState } from 'react'

import { Tabbar, TabbarLink, Icon } from 'konsta/react';
import { MdDashboard, MdAccountCircle, MdSettings, MdKeyboardTab } from 'react-icons/md';
import { EnvelopeFill, Calendar, CloudUploadFill } from 'framework7-icons/react';

import { usePathname } from 'next/navigation'
import { useRouter } from 'next/navigation';

import { useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';

import { SDKWrapper } from '@/lib/helpers/directus/SDKWrapper';

export default function TabBar() {
    const [activeTab] = useState(usePathname());
    const router = useRouter()

    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);
    const sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

    return (
        <Tabbar
            colors={{
                bgMaterial: "bg-primary-300 dark:bg-primary-300",
                textMaterial: "text-primary-800 dark:text-primary-800",
            }}
            style={{ zIndex: 999 }}
            // labels={true}
            icons={true}
            className="right-0 left-0 bottom-0 fixed rounded-t-2xl overflow-hidden"
        >
            <TabbarLink
                active={activeTab === '/'}
                onClick={() => { router.push('/') }}
                colors={{
                    bgMaterial: "bg-primary-300 dark:bg-primary-300",
                    textMaterial: "text-primary-800 dark:text-primary-800",
                }}
                icon={
                    (
                        <Icon
                            className='active-no-bg-change'
                            ios={<EnvelopeFill className="w-7 h-7" />}
                            material={<MdDashboard className="w-6 h-6" />}
                        />
                    )
                }
                label={'Dashboard'}
            />
            <TabbarLink
                active={activeTab === '/account'}
                onClick={() => { router.push('/account') }}
                colors={{
                    bgMaterial: "bg-primary-300 dark:bg-primary-300",
                    textMaterial: "text-primary-800 dark:text-primary-800",
                }}
                icon={
                    (
                        <Icon
                            ios={<Calendar className="w-7 h-7" />}
                            material={<MdAccountCircle className="w-6 h-6" />}
                        />
                    )
                }
                label={'Profiel'}
            />

            {/* if logged in */}
            {localStorageObject.isLoggedIn ? (
                <TabbarLink
                    onClick={() => { sdkWrapper.logout() }}
                    colors={{
                        bgMaterial: "bg-primary-300 dark:bg-primary-300",
                        textMaterial: "text-primary-800 dark:text-primary-800",
                    }}
                    icon={
                        (
                            <Icon
                                ios={<CloudUploadFill className="w-7 h-7" />}
                                material={<MdKeyboardTab className="w-6 h-6" />}
                            />
                        )
                    }
                    label={'Uitloggen'}
                />
            ) : (
                <TabbarLink
                    active={activeTab === '/account/login'}
                    onClick={() => { router.push('/account/login') }}
                    colors={{
                        bgMaterial: "bg-primary-300 dark:bg-primary-300",
                        textMaterial: "text-primary-800 dark:text-primary-800",
                    }}
                    icon={
                        (
                            <Icon
                                ios={<CloudUploadFill className="w-7 h-7" />}
                                material={<MdKeyboardTab className="w-6 h-6" />}
                            />
                        )
                    }
                    label={'Uitloggen'}
                />
            )}
        </Tabbar>
    )
}
