'use client'

import React, { useState, Suspense, useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';

import { usePathname } from 'next/navigation'

import {
	App,
	Page,
    Navbar,
} from 'konsta/react';
import Loading from '@/components/Loading';

import { guestPaths } from '@/lib/constants/middlewarePaths';

import TabBar from '@/components/global/TabBar';

import { useRouter } from 'next/navigation';

export default function Template({ children }) {
    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);

    const pathname = usePathname()
    const [needsAuth] = useState(!guestPaths.includes(pathname));

    const router = useRouter()

    return (
        <Suspense>
            <App theme="material" style={{
                minHeight: '100dvh',
            }}>
                <Page className='flex flex-col bg-primary-50 dark:bg-primary-50'>
                    <Navbar colors={{bgMaterial: 'bg-primary-300 dark:bg-primary-300'}} style={{ zIndex: 999, background: "transparent" }} className='px-4 w-full flex' left={(
                        <img src='/images/Logo wit.png' alt='logo' className='h-20 cursor-pointer' onClick={() => { router.push('/') }} />
                    )} />
                    {localStorageObject.loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className={`${needsAuth ? 'pb-24' : 'pb-0'} w-full flex-1 px-4 overflow-x-hidden`}>
                                {children}
                            </div>

                            {needsAuth && (
                                <TabBar />
                            )}
                        </>
                    )}
                </Page>
            </App>
        </Suspense>
	);
}